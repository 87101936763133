import * as React from "react"
import * as pageStyles from "../components/pageContent.module.css"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql, Link } from "gatsby"
import { SquareFill } from "react-bootstrap-icons"
import * as JobPostsStyles from "../components/jobPostsPage.module.css"
import { Container, Row, Col, Button } from "react-bootstrap"

const Post = ({ data }) => {
  const postData = data.mysqlPosts

  console.log(postData)

  return (
    <Layout splashPage={false} pageTitle={postData.vacancy_type}>
      <Seo title={postData.vacancy_type} />

      <Container className={JobPostsStyles.pageBodyText}>
        <Row className={JobPostsStyles.row}>
          <Col>
            <span className={JobPostsStyles.pageText}>
              <SquareFill color="#ce5f26" /> {postData.vacancy_subtitle}
            </span>
          </Col>
        </Row>
        <Row className={JobPostsStyles.row}>
          <Col>
            <span className={JobPostsStyles.pageheadingText}>
              Vacancy Reference:
            </span>
          </Col>
          <Col>{postData.vacancy_reference}</Col>
        </Row>
        <Row className={JobPostsStyles.row}>
          <Col>
            <span className={JobPostsStyles.pageheadingText}>Location:</span>
          </Col>
          <Col>{postData.vacancy_location}</Col>
        </Row>
        {/* <Row className={JobPostsStyles.row}>
          <Col>
            <span className={JobPostsStyles.pageheadingText}>
              Vacancy Title:
            </span>
          </Col>
          <Col>{postData.vacancy_subtitle}</Col>
        </Row> */}
        <Row className={JobPostsStyles.row}>
          <Col>
            <span className={JobPostsStyles.pageheadingText}>The Company:</span>

            <p className={JobPostsStyles.para}>
              {postData.vacancy_the_company}
            </p>
          </Col>
        </Row>

        <Row className={JobPostsStyles.row}>
          <Col>
            <span className={JobPostsStyles.pageheadingText}>
              Role Details:
            </span>
            <div
              className={JobPostsStyles.para}
              dangerouslySetInnerHTML={{
                __html: postData.vacancy_the_role,
              }}
            />
          </Col>
        </Row>

        <Row className={JobPostsStyles.row}>
          <Col>
            <span className={JobPostsStyles.pageheadingText}>
              Salary & Benefits:
            </span>
            <p className={JobPostsStyles.para}>{postData.vacancy_salary}</p>
          </Col>
        </Row>

        {/* <Row className={JobPostsStyles.row}>
          <Col>
            <span className={JobPostsStyles.pageheadingText}>About you:</span>
            <div
              dangerouslySetInnerHTML={{
                __html: postData.vacancy_candidate_requirements,
              }}
            />
          </Col>
        </Row> */}
        {postData.work_in_uk === 1 ? (
          <Row className={JobPostsStyles.row}>
            <Col>
              You will need to have the right to work in the UK to be considered
              for this role, along with all the necessary supporting documents.
            </Col>
          </Row>
        ) : null}

        <Row className={JobPostsStyles.row}>
          <Col>
            <Link
              to={
                "https://cms.miseenplace.co.uk/candidates/?" +
                postData.vacancy_reference
              }
            >
              <Button className={JobPostsStyles.applyButton}>Apply now</Button>
            </Link>
          </Col>
          {/* <Col>
            <Button variant="primary">Primary</Button>
          </Col> */}
        </Row>
      </Container>
    </Layout>
  )
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query($PostId: Int!) {
    mysqlPosts(mysqlId: { eq: $PostId }) {
      mysqlId
      slug
      type_id
      vacancy_type
      vacancy_subtitle
      vacancy_reference
      vacancy_location
      vacancy_the_company
      vacancy_the_role
      vacancy_candidate_requirements
      vacancy_salary
      vacancy_salary_amount
      work_in_uk
    }
  }
`

export default Post
