// extracted by mini-css-extract-plugin
export var pageText = "jobPostsPage-module--pageText--eWgf8";
export var pageBodyText = "jobPostsPage-module--pageBodyText--cQ7G9";
export var pageheadingText = "jobPostsPage-module--pageheadingText--qC4Yp";
export var applyButton = "jobPostsPage-module--applyButton--hV8Cm";
export var card = "jobPostsPage-module--card--scpUy";
export var row = "jobPostsPage-module--row--4ewrL";
export var para = "jobPostsPage-module--para---bY6B";
export var cardText = "jobPostsPage-module--cardText--dx-IB";
export var cardTextHeader = "jobPostsPage-module--cardTextHeader--FqDG-";
export var cardTextLocation = "jobPostsPage-module--cardTextLocation--Vj1eO";
export var cardTextSalary = "jobPostsPage-module--cardTextSalary--RpcAN";